@tailwind base;
@tailwind components;
@tailwind utilities;

.css-ob3on5-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 3.5px 4px 7.5px 5px !important;
}

.MuiAutocomplete-inputRoot .css-1qnrw8l-MuiInputBase-root-MuiOutlinedInput-root {
  height: 44px !important;
}

header img {
  height: 80px;
}

main {
  color: white;
}

header {
  background-color: white;
  height: 65px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 5px 0 black;
  justify-content: space-between;
  padding: 20px;
}

header * {
  display: inline;
}

header ul {
  display: flex;
  gap: 50px;
  font-size: 18px;
  margin-left: -100px;
}
.active {
  color: #db2228;
  font-weight: 500;
}
.pending {
  color: #000;
}
.body {
  padding: 5px;
  margin-top: 10px;
}
